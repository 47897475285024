import React from 'react';

import privacy from 'data/privacy.json';
import { usePrivacyPolicyMarkdown } from 'hooks';

import { Container, Content, MarkdownContent, Title, Updated } from './PrivacyPolicy.style';

export function PrivacyPolicy() {
    const privacyPolicyMarkdown = usePrivacyPolicyMarkdown();

    return (
        <Container>
            <Content>
                <Title>{privacy.title}</Title>
                <Updated>{privacy.updated}</Updated>
                {privacyPolicyMarkdown.length > 0 && (
                    <MarkdownContent dangerouslySetInnerHTML={{ __html: privacyPolicyMarkdown }} />
                )}
            </Content>
        </Container>
    );
}

import React from 'react';

import seo from 'data/seo.json';
import { SEO } from 'components/ui';
import { PrivacyPolicy } from 'components/privacy';

export default function Privacy() {
    return (
        <>
            <SEO title={seo.privacyTitle} description={seo.privacyDescription} />
            <PrivacyPolicy />
        </>
    );
}
